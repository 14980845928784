<template>
  <div id="app">
    <HomeIndex />
  </div>
</template>

<script>
import HomeIndex from './views/home/index'
export default {
  name: 'App',
  components: { HomeIndex }
}
// 根绝屏幕尺寸判断根节点文字大小
document.addEventListener('DOMContentLoaded', () => {
  let width = document.documentElement.clientWidth || document.body.clientWidth;
  let fontsize = (width <= 1200 ? 1200 : width) / 100 + 'px';
  document.querySelector('html').style.fontSize = fontsize
})
</script>
<style>
html {
  font-size: 5.2vw
}
body {
  margin: 0 !important;
}
</style>
<style scoped>
* {
  box-sizing: border-box;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
}
</style>