<template>
  <div class="home-container">
    <div class="home-header">
      <div class="h-h-top">
        <div class="h-h-t-logo">
          <img src="../../assets/home/logo1.png" alt="" style="width: 100%" />
        </div>
        <div class="h-h-t-info">
          <a
            v-for="(item, index) in topBar"
            :key="index"
            :href="'#scroll-item' + index"
            >{{ item.name }}</a
          >
        </div>
      </div>
      <div class="h-h-bottom">
        <p class="h-h-b-text">懂营销<span></span>更懂元宇宙</p>
        <div class="h-h-b-line"></div>
        <!-- <div class="h-h-b-btn">
          Explore more
          <img src="../../assets/home/arrow.png" alt="" />
        </div> -->
      </div>
    </div>
    <div class="home-part2" id="scroll-item1">
      <div class="h-p2-content">
        <div class="h-p2-c-left">
          <p class="h-p-title">好奇莫斯</p>
          <div class="h-p-line"></div>
          <p class="h-p-subtitle" style="margin-bottom: 38px">
            元宇宙品牌营销专家
          </p>
          <p class="h-p2-c-l-cont">
            好奇莫斯是由浙文互联打造的品牌数字藏品及元宇宙营销服务代理公司。通过领先的营销理念和创意能力、先进的NFT和元宇宙技术支持、成熟的市场运营体系、优质的IP资源，布局“技术+创意+数字营销+新零售”的组合战略，为品牌提供从数字藏品到元宇宙的全行业营销解决方案。
          </p>
        </div>
        <div class="img">
          <img
            src="../../assets/home/part2.png"
            alt=""
            style="width: 100%; height: 100%"
          />
        </div>
      </div>
    </div>
    <div class="home-part3" id="scroll-item2">
      <p class="h-p-title">元宇宙全域服务</p>
      <div class="h-p-line"></div>
      <div class="h-p3-content" style="margin-top:35px">
        <div class="h-p3-c-item" style="margin-right: 112px;padding-left: 56px">
          <img src="@/assets/home/part3.1.png" alt="" />
          <div>
            <p>技术底座</p>
            <span>Web3及区块链底层自有技术及深度合作</span>
            <span>VRXR及沉浸式互动</span>
          </div>
        </div>
        <div class="h-p3-c-item" style="padding-right: 56px;justify-content: flex-end;">
          <div style="align-items: flex-end;">
            <p>元宇宙空间</p>
            <span>自有元宇宙 大唐灵境</span>
            <span>品牌元宇宙定制</span>
          </div>
          <img src="@/assets/home/part3.2.png" alt="" style="margin:0 0 0 65px"/>
        </div>
      </div>
      <div class="h-p3-content">
        <div class="h-p3-c-item" style="margin-right: 112px;padding-left: 56px">
          <img src="@/assets/home/part3.3.png" alt="" />
          <div>
            <p>虚拟人</p>
            <span>品牌虚拟人设计、技术支持</span>
            <span>虚拟人IP运营</span>
          </div>
        </div>
        <div class="h-p3-c-item" style="padding-right: 56px;justify-content: flex-end;">
          <div style="align-items: flex-end;">
            <p>数字藏品及数字艺术嘉年华</p>
            <span>NAAS数字藏品解决方案</span>
            <span>品牌web3巡展计划</span>
          </div>
          <img src="@/assets/home/part3.4.png" alt="" style="margin:0 0 0 65px" />
        </div>
      </div>
    </div>
    <div class="home-part4">
      <p class="h-p-title">四大优势储备</p>
      <div class="h-p-line"></div>
      <p class="h-p4-subtitle">奠定行业领先地位</p>
      <div class="h-p4-content">
        <div
          v-for="(item, index) in part4List"
          :key="index"
          class="h-p4-c-item"
        >	
					<div class="img-wrap">
						<img :src="item.imgPath" alt="" style="width: 100%" />
					</div>
         
          <div class="type">{{ item.type }}</div>
          <p>{{ item.cont1 }}</p>
          <p>{{ item.cont2 }}</p>
          <p>{{ item.cont3 }}</p>
        </div>
      </div>
    </div>
    <div class="home-part5" id="scroll-item3">
      <p class="h-p-title">Case案例</p>
      <div class="h-p-line"></div>
      <div class="h-p5-content">
        <div class="h-p5-c-item" style="cursor: pointer;" @click="jump('https://mp.weixin.qq.com/s/cuBLZA-UlY_XlE6sj7ze3w')">
          <img src="@/assets/home/part5.1.png" alt="">
          <span>好奇莫斯轻量化AI数字人应用“好奇数眸”</span>
          <span>“2分钟生成带着走的数字人”</span>
          <span>11月23日在杭州数贸会首次亮相</span>
        </div>
        <div class="h-p5-c-item">
          <img src="@/assets/home/part5.2.png" alt="">
          <span>6.28君若锦作客渣渣辉直播间</span>
          <span>19届杭州亚运会电竞项目特邀KOL</span>
          <span>数字人+AIGC</span>
        </div>
        <div class="h-p5-c-item">
          <img src="@/assets/home/part5.3.png" alt="">
          <span>阳澄湖 希腊神话AR艺术乐园</span>
          <span>元宇宙线下第一站</span>
        </div>
        <div class="h-p5-c-item">
          <img src="@/assets/home/part5.4.png" alt="">
          <span>中国首个唐文化主题的元宇宙空间</span>
          <span>打造“数字+文化+旅游”全新沉浸式体验空间</span>
        </div>
      </div>
    </div>
    <div class="home-part6">
      <div class="h-p6-title-box">
        <p class="h-p-title">COOPERATION OPPORTUNITY</p>
        <div class="h-p-subtitle">合作机会</div>
        <div class="h-p-line"></div>
      </div>
      <div class="h-p6-content">
        <div class="h-p6-c-left">
          <p>１．品牌(虚拟人)入驻及产品展示</p>
          <p>２．品牌自有虚拟场地</p>
          <p>３．线上及线下元宇宙沉浸式场景</p>
          <p>４．围绕元宇宙的数字藏品及数字资产延伸</p>
        </div>
        <img src="@/assets/home/part6.png" alt="" />
      </div>
    </div>
    <div class="home-part7" id="scroll-item4">
      <div class="h-p6-title-box">
        <p class="h-p-title">CONTACT US</p>
        <div class="h-p-subtitle">联系我们</div>
        <div class="h-p-line"></div>
      </div>
      <div class="h-p7-content">
        <div class="h-p7-c-left">
          <img src="@/assets/home/map.jpg" alt="" @click="jump('https://map.baidu.com/search/%E5%A5%BD%E5%A5%87%E8%8E%AB%E6%96%AF(%E5%8C%97%E4%BA%AC)%E6%96%87%E5%8C%96%E4%BC%A0%E6%92%AD%E7%A7%91%E6%8A%80%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8/@12973822.855,4826569.78,19z?querytype=s&da_src=shareurl&wd=%E5%A5%BD%E5%A5%87%E8%8E%AB%E6%96%AF(%E5%8C%97%E4%BA%AC)%E6%96%87%E5%8C%96%E4%BC%A0%E6%92%AD%E7%A7%91%E6%8A%80%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8&c=131&src=0&wd2=%E5%8C%97%E4%BA%AC%E5%B8%82%E6%9C%9D%E9%98%B3%E5%8C%BA&pn=0&sug=1&l=13&b=(12946863,4814780;13006767,4844348)&from=webmap&biz_forward=%7B%22scaler%22:1,%22styles%22:%22pl%22%7D&sug_forward=fe6432044c8972032fe67b22&device_ratio=1')" />
        </div>
        
        <div class="h-p7-c-right">
          <p style="margin-bottom:40px;font-size:30px;font-weight:500">欢迎来访</p>
          <p>公司地址：北京市朝阳区东亿国际传媒产业园元君书苑F1座</p>
          <p style="color: #36A6E0;">Email：<a
            target="_top"
            href="mailto:HQMOSS@hqmoss.com"
          >HQMOSS@hqmoss.com</a>
          </p>
          <p>Tel：13121008897（好奇莫斯商务部）</p>
        </div>
      </div>
    </div>
    <div class="home-footer">
      <div class="h-f-logo">
        <img src="@/assets/home/logo2.png" alt="" />
      </div>
      <div class="h-f-qr">
        <img src="@/assets/home/fuwuhao.png" alt="" />
        <span>好奇莫斯服务号</span>
      </div>
      <div class="h-f-qr">
        <img src="@/assets/home/dingyuehao.png" alt="" />
        <span>好奇莫斯订阅号</span>
      </div>
      <div class="h-f-qr">
        <img src="@/assets/home/xiaochengxu.png" alt="" />
        <span>好奇莫斯小程序</span>
      </div>
    </div>
    <div class="copyright">
      <a
        href="https://beian.miit.gov.cn/"
        target="_blank"
        style="margin-right: 8px"
        >京ICP备2023021409号-1</a
      >
      <a
        target="_blank"
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502053411"
      >
        <img src="@/assets/home/gwab.png" />京公网安备 11010502053411号
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeIndex",
  data() {
    return {
      topBar: [
        { name: "首页" },
        { name: "关于我们" },
        { name: "元宇宙服务" },
        // { name: "动态洞察" },
        { name: "案例" },
        { name: "联系我们" },
        // { name: "技术支持" },
      ],
      part4List: [
        {
          imgPath: require("../../assets/home/jishuchubei.png"),
          type: "技术储备",
          cont1: "领先的只能营销技术",
          cont2: "领先的区块链和元宇宙技术",
          cont3: "成熟的数量及流量运营技术",
        },
        {
          imgPath: require("../../assets/home/zizhichubei.png"),
          type: "资质储备",
          cont1: "国资背景政策赋能",
          cont2: "元宇宙产业委员会成员",
          cont3: "NFT及元宇宙产业头部平台含略合作",
        },
        {
          imgPath: require("../../assets/home/shengtaichubei.png"),
          type: "生态储备",
          cont1: "从智能营销、数字藏品开发",
          cont2: "数字藏品交易市场、虚拟人、AR/VR",
          cont3: "乃至元宇宙建设的全链路服务平台",
        },
        {
          imgPath: require("../../assets/home/ip.png"),
          type: "IP储备",
          cont1: "自有虚拟人IP及海外NFT IP",
          cont2: "国内最强影视IP资源阵容",
          cont3: "海内外顶级艺术IP及知名加密艺术家",
        },
      ],
    };
  },
  methods: {
    jump(url) {
      window.open(url)
    }
  }
};
</script>

<style scoped>
p {
  height: auto;
  padding: 0;
  margin: 0;
}
.home-container {
  width: 100%;
  height: 100%;
  min-width: 1200px;
  margin: 0 auto;
  background: #f1f1f1;
	overflow-y: auto;
}
.home-header {
  width: 100%;
  /* height: 960px; */
  max-height: 767px;
  background: url("../../assets/home/banner.png") no-repeat;
  background-size: 100%;
  padding-top: 40%;
  position: relative;
}
.h-h-top {
  position: absolute;
  padding: 0 130px 0 108px;
  top: 20px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}
.h-h-t-logo {
  width: 140px;
}
.h-h-t-info {
  display: flex;
  /* align-items: center; */
	padding-top: 22px;
}
.h-h-t-info a {
  margin-right: 30px;
  font-size: 20px;
  font-family: PingFang;
  font-weight: 400;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
.h-h-bottom {
  position: absolute;
  bottom: 32px;
  right: 154px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.h-h-b-text {
  font-size: 50px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  padding: 0;
  margin: 0;
}
.h-h-b-text span {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin: 0 8px;
}
.h-h-b-line {
  width: 200px;
  height: 2px;
  background: #ffffff;
  opacity: 0.8;
  margin: 20px 0 50px 0;
}
.h-h-b-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 278px;
  height: 58px;
  background: #fff;
  font-size: 20px;
}
.h-h-b-btn img {
  width: 28px;
  margin-left: 30px;
}

.home-part2 {
  padding: 100px;
}
.h-p2-content {
  width: 100%;
  display: flex;
  background: #fff;
}
.h-p2-c-left {
  box-sizing: border-box;
  width: 50%;
  padding: 0 146px 0 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.h-p2-c-l-cont {
  width: 100%;
  /* padding: 0 7.214px; */
  font-size: 24px;
  font-weight: 400;
  color: #7c7c7c;
  line-height: 36px;
}
.h-p-title {
  font-size: 48px !important;
  padding: 0;
  font-weight: 500 !important;
  color: #000000 !important;
}
.h-p-line {
  width: 25px;
  height: 3px;
  background: #2e2c2d;
  margin: 12px 0 20px 0;
}
.h-p2-content .img {
  width: 50%;
}
.home-part3 {
  width: calc(100% - 224px);
  padding: 100px 112px 98px 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fff;
}
.h-p3-content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 62px;
}
.h-p3-c-item {
  width: 45%;
  background: #EFEFEF;
  display: flex;
  align-items: center;
  padding: 30px 0;
}
.h-p3-c-item img {
  width: 252px;
  height: 252px;
  margin-right: 65px;
}
.h-p3-c-item div {
  display: flex;
  flex-direction: column;
}
.h-p3-c-item p {
  margin-bottom: 40px;
  font-size: 30px;
  font-weight: 500;
  color: #000000;
}
.h-p3-c-item span {
  font-size: 20px;
  font-weight: 400;
  color: #121212;
  line-height: 30px;
}
.home-part4 {
  width: calc(100% - 336px);
  padding: 100px 140px 100px 196px;
  display: flex;
  flex-direction: column;
  background: url("../../assets/home/part4_bg.png") no-repeat;
  background-size: 100% 100%;
}
.h-p4-content {
  display: flex;
  justify-content: space-between;
  margin-top: 76px;
}
.h-p4-c-item .img-wrap {
  max-width: 50px;
	height: 50px;
}
.h-p4-c-item .type {
  margin: 17px 0 50px;
  font-size: 28px;
  font-weight: 500;
  color: #010101;
}
.h-p4-c-item p {
  font-size: 18px;
  font-weight: 500;
  color: #666666;
  margin-bottom: 10px;
}
.h-p4-subtitle {
  font-size: 30px;
  font-weight: 400;
  color: #000000;
}
.home-part5 {
  padding: 98px 98px 0;
  background: #ffffff;
}
.h-p5-content {
  background: #EFEFEF;
  display: flex;
  justify-content: space-between;
  padding: 112px 22px;
  /* overflow-x: auto; */
}
.h-p5-c-item {
  width: 23%;
  margin: 0 1%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  font-weight: 400;
  color: #121212;
}
.h-p5-c-item img{
  width: 100%;
  /* max-height: 220px; */
  margin-bottom: 50px;
}
.h-p5-c-item span {
  line-height: 34px;
}
/* .home-part5 img {
  max-width: 100%;
  margin-top: 28px;
} */
.h-p6-title-box {
  background: #ffffff;
  padding: 112px 0 3.98px 98px;
}
.h-p6-content {
  display: flex;
  align-items: center;
}
.h-p6-c-left, .h-p7-c-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
.h-p6-c-left p{
  font-size: 30px;
  font-weight: 400;
  color: #000000;
  line-height: 70px;
  margin-left: 140px;
}
.h-p6-content img {
  max-width: 50%;
}
.h-p7-content {
  display: flex;
  align-items: center;
  padding: 0 0 100px 98px;
  background: #fff;
}
.h-p7-c-left {
  width: 40%;
  flex-shrink: 0;
  border-radius: 10px;
  overflow: hidden;
  /* padding: 0 80px; */
  box-sizing: border-box;
}
.h-p7-c-left img {
  width: 100%;
  cursor: pointer;
}
.h-p7-c-right p {
  font-size: 20px;
  font-weight: 400;
  color: #121212;
  line-height: 50px;
  margin-left: 140px;
}
.h-p7-c-right p a {
  text-decoration: none;
  color: #36A6E0;
}
.h-p-subtitle {
  font-size: 30px;
  font-weight: 500;
  color: #000000;
}
.home-footer {
  background: #d0dceb;
  padding: 90px 198px 42px 198px;
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}
.h-f-logo {
  width: 180px;
  flex-shrink: 0;
  margin-right: 252px;
}
.h-f-logo img {
  width: 100%;
}
.h-f-qr {
  flex-shrink: 0;
  width: 180px;
  margin: 0 90px;
  font-size: 20px;
  font-weight: 400;
  color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.h-f-qr img {
  width: 100%;
  margin-bottom: 20px;
}
.copyright {
  height: 60px;
  line-height: 60px;
  background: #b9c7d7;
  display: flex;
  align-items: center;
  justify-content: center;
}
.copyright a {
  display: flex;
  align-items: center;
  text-decoration: none;
}
</style>